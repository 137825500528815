import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navigation from './components/navigation_bar';
import Welcome from './components/welcome';
import About from './pages/pg_about';
import Contact from './pages/pg_contact';
import Experience from './pages/pg_experience';
import Projects from './pages/pg_projects';
import References from './pages/pg_references';

function App() {
  
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/references" element={<References />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
