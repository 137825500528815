import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import './pg_contact.css';
import sendMailIcon from '../icons/sendMail.svg';
import send from '../icons/send.svg';


import phone from '../icons/phone.svg';
import location from '../icons/location.svg';
import email from '../icons/email.svg';

import phoneWhite from '../icons/light/phone.svg';
import locationWhite from '../icons/light/location.svg';
import emailWhite from '../icons/light/emailWhite.svg';


import instagram from '../icons/light/instagram.svg';
import linkedin from '../icons/light/linkedin.svg';
import github from '../icons/light/github.svg';
import emailjs from 'emailjs-com'; 
import cool from '../images/cool.svg';
import cool2 from '../images/cool2.svg';


function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1200);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      await emailjs.send(
        'service_6ff9raf', 
        'template_hzydkgl', 
        formData, 
        'bmx9g8h5OtqyNwE68' 
      );
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const locationIcon = isWideScreen ? location : locationWhite;
  const phoneIcon = isWideScreen ? phone : phoneWhite;
  const emailIcon = isWideScreen ? email : emailWhite;
  return (
    <div>
    <div className="contact_page">
      <div className='bubble_box_right'>
          <div className="contact_information_title">
            Contact Information
          </div>
          <div className="location_box">
            <div className="location_icon">
              <img className="icon" src={locationIcon} alt="Location Icon" />
            </div>
            <div className="text">
              University City, MO 63130
            </div>
          </div>
          <div className="location_box">
            <div className="phone_icon">
              <img className="icon" src={phoneIcon} alt="Phone Icon" />
            </div>
            <div className="text">
              +1(314)-393-5186
            </div>
          </div>
          <div className="location_box">
            <div className="email_icon">
              <img className="icon" src={emailIcon} alt="Email Icon" />
            </div>
            <div className="text">
              b.kaden@wustl.edu
            </div>
          </div>
        </div>
      <div className="bubble_box">
        <div className="bubble_box_left">
          <div className='title_box'>
            <div className='contact_title'>
              Send me a message!
            </div>
            <div className='contact_title_icon'>
              <img className="icon_send_mail" src={sendMailIcon} alt="Send Mail Icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="bigger_box">
            <div className='box_firstname'>
              <div className="context_title">First Name</div>
              <input 
                className="inputBox" 
                type="text" 
                name="firstName" 
                id="firstName" 
                placeholder="" 
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className='box_lastname'>
              <div className="context_title">Last Name</div>
              <input 
                className="inputBox" 
                type="text" 
                name="lastName" 
                id="lastName" 
                placeholder="" 
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            </div>
            <div className='box'>
              <div className="context_title">Email</div>
              <input 
                className="inputBox" 
                type="email" 
                name="email" 
                id="email" 
                placeholder="" 
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className='message_box'>
              <div className="context_title">Message</div>
              <textarea 
                className="textArea" 
                name="message" 
                id="message" 
                placeholder="" 
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className='send_box'>
              <button type="submit" className="send_button">
                <img className="icon_send_button" src={send} alt="Send Icon" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="contact_page_fake">
      <div className='left_cool'>
        <img className="cool"src={cool} alt="cool"></img>
      </div>
      <img className="cool2"src={cool2} alt="cool"></img>
    </div>
    </div>
  );
}

export default Contact;
