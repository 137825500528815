import React, { useState, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './navigation_bar.css';
import homeIconDark from '../icons/dark/home.svg';
import homeIconLight from '../icons/light/home.svg';

import menuIcon from '../icons/dark/menuIcon.svg';

const Navigation: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pressedIndex, setPressedIndex] = useState<number | null>(null);
  const navLinksRef = useRef<(HTMLAnchorElement | null)[]>([]);
  const location = useLocation();

  const isHomeRouteActive = location.pathname === '/';

  const handlePressDown = (index: number) => {
    setPressedIndex(index);
    setMenuOpen(false); 
    setTimeout(() => setPressedIndex(null), 200); // Reset the pressed index after animation duration
  };

  return (
    <nav >
      <Link
        className={`home ${pressedIndex === -1 ? 'press-down' : ''}`}
        to="/"
        onClick={() => handlePressDown(-1)}
        style={{ backgroundColor: isHomeRouteActive ? '' : '' }}
      >
        <img className="homeIcon" src={isHomeRouteActive ?homeIconDark : homeIconLight} alt="Home" />
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <img src={menuIcon} alt="menu"></img>
      </div>
      <ul className={menuOpen ? 'open' : ''}>
        
        {['experience', 'projects', 'references', 'contact'].map((path, index) => (
          <li key={path}>
            <NavLink
              to={`/${path}`}
              ref={el => navLinksRef.current[index] = el}
              onClick={() => handlePressDown(index)}
              className={pressedIndex === index ? 'press-down' : ''}
            >
              {path.charAt(0).toUpperCase() + path.slice(1)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
