import React, { useEffect, useState } from 'react';
import './pg_projects.css';
import firebaseIcon from '../images/firebase.png';
import swiftIcon from '../images/swift.svg';
import twilioIcon from '../icons/twilioIcon.svg';
import jsIcon from '../icons/jsIcon.svg';
import phpIcon from '../icons/php.svg';
import pythonIcon from '../icons/python_logo.svg';
import flaskIcon from '../icons/flask.svg';
import excelIcon from '../icons/excel.png';
import ibmIcon from '../icons/ibm.svg';
import twilio_flow from '../images/Twilio_Flow.png';
import tastespaceIcon from '../images/tastespace.png';
import tastespaceEx from '../images/tastespace_ex.png';
import KUTE from 'kute.js';

import phoneOneTaste from '../images/phoneOneTaste.svg';
import phoneTwoTaste from '../images/phoneTwoTaste.svg';
import phoneThreeTaste from '../images/phoneThreeTaste.svg';
import phoneFourTaste from '../images/phoneFourTaste.svg';

import lanLubberPhone from '../images/lanLubberPhone.svg';
import laptopLanLubber from '../images/laptopLanlubber.svg';

import next from '../icons/dark/next.svg';

import blob from '../images/blob3.gif';
import blob2 from '../images/blob4.gif';
import blob3 from '../images/blob5.gif';

import dashboardOne from '../images/laptopDashboardOne.svg';
import dashboardTwo from '../images/laptopDashboardTwo.svg';
import dashboardThree from '../images/laptopDashboardThree.svg';

const dashboardImages = [
  dashboardOne,
  dashboardTwo,
  dashboardThree
];
const phoneImages = [
  phoneOneTaste,
  phoneTwoTaste,
  phoneThreeTaste,
  phoneFourTaste,
];

function Projects() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndexDashboard, setCurrentImageIndexDashboard] = useState(0);

  useEffect(() => {
    const intervalOne = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % phoneImages.length);
    }, 5000);
  
    const intervalTwo = setInterval(() => {
      setCurrentImageIndexDashboard((prevIndex) => (prevIndex + 1) % dashboardImages.length);
    }, 5000);
  
    return () => {
      clearInterval(intervalOne);
      clearInterval(intervalTwo);
    };
  }, []);
  

  return (
    <div className="Projects">
      <div className="tastespace">
        <div className="tastespace_title">
          Tastespace.
        </div>
        <div className="tastespace_subtitle">
          Social media for recipes
        </div>
      </div>
      <div className="display">
        <div className="right_side_taste">
          <div className="introduction_row">
            <div className="introduction_row_title">
              Target Audience
            </div>
            <div className="introduction_row_description">
              College students looking for quick, affordable, and satisfying meals.
            </div>
          </div>
          <div className="introduction_row">
            <div className="introduction_row_title">
              App Concept
            </div>
            <div className="introduction_row_description">
              Peek into friends' kitchens for meal inspiration and create a space for students to share and satisfy culinary cravings.
            </div>
          </div>
          <div className="introduction_row_list">
            <div className="introduction_row_title">
              App Features
            </div>
            <div className="introduction_row_description_list">
              <ul className="custom-list">
                <li>Post and save your favorite recipes.</li>
                <li>Receive a personalized recipe feed tailored to your tastes.</li>
                <li>Explore a community feed to find new friends and discover unique recipes.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="left_side_taste">
          {phoneImages.map((image, index) => (
            <img
              key={index}
              className={`tastespace_iphone ${index === currentImageIndex ? 'active' : ''}`}
              src={image}
              alt={`Phone showcasing Tastespace app ${index + 1}`}
            />
          ))}

          {/* https://blobs.xom9ik.com/ */}
          <img className="blob3" src={blob3} alt=""></img>
          <img className="blob" src={blob} alt=""></img>
          <img className="blob2" src={blob2} alt=""></img>

        </div>
        <div className="tech_stack_icons">
          <img className="tech_iconTop" src={firebaseIcon} alt=""></img>
          <img className="tech_iconTop" src={swiftIcon} alt=""></img>
        </div>
      </div>
      <div className="row_projects">
        <a href="https://github.com/moyeshkhanal/TasteSpace" target="_blank" rel="noopener noreferrer" className="linkWrap">
          <div className="row_end_projects">
            <div className="seeCodeText">
              Learn more
            </div>
            <img className="next" src={next} alt=""></img>
          </div>
        </a>
      </div>
      <div className="tastespace">
        <div className="tastespace_title">
          Lanlubber.
        </div>
        <div className="tastespace_subtitle">
          Modern Landline
        </div>
      </div>
      <div className="display">
        <div className="right_side_lan">
          <div className="introduction_row">
            <div className="introduction_row_title">
              Concept
            </div>
            <div className="introduction_row_description">
              A modern landline system that allows you to make group or individual calls to anyone within a home, without the hardware of traditional landline systems.
            </div>
          </div>
          <div className="introduction_row">
            <div className="introduction_row_title">
              Target Audience
            </div>
            <div className="introduction_row_description">
              Families and friends who want to reach someone at home, see who is available, or contact anyone regardless of their location within the home.
            </div>

          </div>
          <div className="introduction_row_list">
            <div className="introduction_row_title">
              Features
            </div>
            <div className="introduction_row_description">
              <ul className="custom-list">
                <li>Initiate calls to a virtual number that contacts all designated individuals within the home vicinity.</li>
                <li>Ring multiple numbers simultaneously, allowing one or more people to join the call.</li>
                <li>The call can be ended by the person who initiated it.</li>
              </ul>
            </div>
          </div>
          {/* <div className="contributors">
            Kaden Bernhard and Ron Cytron
            <div className="github_link">
              <a href="https://github.com/kadenbkk/LANlubber.git" target="_blank" rel="noopener noreferrer">
              https://github.com/kadenbkk/LANlubber.git
              </a>
            </div>
        </div> */}
        </div>


        <div className="left_side_taste">
          <img className="lanLubberPhone" src={lanLubberPhone} alt=""></img>
          <img className="twilio_flow" src={laptopLanLubber} alt=""></img>
          <img className="Tblob3" src={blob3} alt=""></img>
          <img className="Tblob" src={blob} alt=""></img>
          <img className="Tblob2" src={blob2} alt=""></img>
        </div>
        <div className="tech_stack_icons">
          <img className="tech_icon" src={twilioIcon} alt=""></img>
          <img className="tech_icon" src={jsIcon} alt=""></img>
          <img className="tech_icon" src={phpIcon} alt=""></img>
          <img className="tech_icon" src={pythonIcon} alt=""></img>
          <img className="tech_icon" src={swiftIcon} alt=""></img>
        </div>
      </div>
      <div className="row_projects">
        <a id="need_space" href="https://github.com/kadenbkk/LANlubber" target="_blank" rel="noopener noreferrer" className="linkWrap">
          <div className="row_end_projects">
            <div className="seeCodeText">
              Learn more
            </div>
            <img className="next" src={next} alt=""></img>
          </div>
        </a>
      </div>
      <div className="tastespace">
        <div className="tastespace_title">
          Certification.
        </div>
        <div className="tastespace_subtitle">
          IBM Data Analyst Professional
        </div>
      </div>
      <div className="display">
        <div className="right_side_taste">
          <div className="introduction_row">
            <div className="introduction_row_title">
              Goal
            </div>
            <div className="introduction_row_description">
              To diversify my experience and understand data analytics
            </div>
          </div>
          <div className="introduction_row">
            <div className="introduction_row_title">
              Details
            </div>
            <div className="introduction_row_description">
              This was a four month long course provided by IBM in partnership with coursera
            </div>
          </div>
          <div className="introduction_row_list">
            <div className="introduction_row_title">
              Content
            </div>
            <div className="introduction_row_description_list">
              <ul className="custom-listIBM">
                <li>Data Analysis Process: Data extraction, data cleaning, regression analysis, and visualizations</li>
                <li>Proficient in Python libraries including Pyplot, Pandas, Matplotlib, Seaborn, and Jupyter Notebooks, as well as IBM Cognos Analytics, Excel, and SQL</li>
                <li>Developed interactive and adaptive dashboards featuring filters, pivot tables, and dynamic graphs</li>

              </ul>
            </div>
          </div>
        </div>
        <div className="left_side_taste">
          {dashboardImages.map((image, index) => (
            <img
              key={index}
              className={`dashboardIBM ${index === currentImageIndexDashboard ? 'active' : ''}`}
              src={image}
              alt={`Phone showcasing Tastespace app ${index + 1}`}
            />
          ))}

          {/* https://blobs.xom9ik.com/ */}
          <img className="blob3" src={blob3} alt=""></img>
          <img className="blob" src={blob} alt=""></img>
          <img className="blob2" src={blob2} alt=""></img>

        </div>
        <div className="tech_stack_icons">
          <img className="tech_iconTop" src={pythonIcon} alt=""></img>
          <img className="tech_iconTop" src={flaskIcon} alt=""></img>
          <img className="tech_iconTop" src={ibmIcon} alt=""></img>
          <img className="tech_iconTop" src={excelIcon} alt=""></img>
        </div>
      </div>
      <div className="row_projects">
        <a href="https://www.coursera.org/account/accomplishments/professional-cert/7ERF9DH4QRL7" target="_blank" rel="noopener noreferrer" className="linkWrap">
          <div className="row_end_projects">
            <div className="seeCodeText">
              Learn more
            </div>
            <img className="next" src={next} alt=""></img>
          </div>
        </a>
      </div>
      <div className="spacer">
        
      </div>
    </div>
  );
}

export default Projects;
