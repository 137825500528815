import React from 'react';
import './welcome.css';
import portrait from "../images/portrait.jpg";
import email from '../icons/light/email.svg';
import linkedin from '../icons/light/linkedin.svg';
import github from '../icons/light/github.svg';
import download from '../icons/light/download.svg';

function Welcome() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/KadenBernhardResume.pdf';
    link.download = 'KadenBernhardResume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="welcome_parent">
      <div className="welcome_parent_left">
        <div className="card">
          <img className="portait_image" src={portrait} alt=""></img>
        </div>
      </div>
      <div className="welcome_parent_right">
        <div className="textbox_right">
          <div className="subtitle">
            Hi there, I'm...
          </div>
          <div className="welcome_title">
            Kaden Bernhard
          </div>
          <div className="subtitle">
            I created this website to showcase my skills, experience, and exciting projects I've worked on.
          </div>
          <div className="icon_row_row">
            <a className="resumeLink" onClick={handleDownload} download="KadenBernhardResume.pdf">
              <div className="resume_box">
                Resume
                <img className="resume_download" src={download} alt="Download Icon" />
              </div>
            </a>

            <div className="icon_row_box_welcome">
              <div className='icon_box'>
                <a href="https://www.linkedin.com/in/kaden-bernhard-bkk/" target="_blank" rel="noopener noreferrer">
                  <img className="icon_welcome_in" src={linkedin} alt="LinkedIn Icon" />
                </a>
              </div>
              <div className='icon_box'>
                <a href="mailto:b.kaden@wustl.edu">
                  <img className="icon_welcome_insta" src={email} alt="Email Icon" />
                </a>
              </div>
              <div className='icon_box'>
                <a href="https://github.com/kadenbkk" target="_blank" rel="noopener noreferrer">
                  <img className="icon_welcome_git" src={github} alt="GitHub Icon" />
                </a>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Welcome;
