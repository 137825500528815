import React, { useState, useRef, useEffect } from 'react';
import thailand from "../images/thailand.svg";
import japan from "../images/japan.svg";
import usa from "../images/usa.svg";
import './pg_about.css';
import washuLogo from '../images/WashULogo.png';
import isbLogo from '../images/isbLogo.svg';
import timeline from '../images/timeline.png';
import debounce from 'lodash.debounce';
import Welcome from '../components/welcome';

const About: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const userTriggeredScrollRef = useRef<boolean>(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSectionChange = (section: string) => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }
    if (scrollContainerRef.current) {
      scrollContainerRef.current.removeEventListener('scroll', handleScroll);
    }
    setSelectedSection(section);
    const sectionIndex = section === 'topA' ? 0 : section === 'middleA' ? 1 : 2;
    userTriggeredScrollRef.current = true;
    sectionRefs.current[sectionIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    console.log(`Changing section to: ${section}`);

    scrollTimeoutRef.current = setTimeout(() => {
      userTriggeredScrollRef.current = false;
      if (scrollContainerRef.current) {
        scrollContainerRef.current.addEventListener('scroll', handleScroll);
      }
    }, 500);
  };

  const handleScroll = debounce(() => {
    if (userTriggeredScrollRef.current) return;

    if (!scrollContainerRef.current) return;

    const scrollTop = scrollContainerRef.current.scrollTop;
    const containerHeight = scrollContainerRef.current.clientHeight;
    const sectionOffsets = sectionRefs.current.map(ref => ref?.offsetTop || 0);

    const closestSection = sectionOffsets.reduce((prev, curr) => {
      return Math.abs(curr - (scrollTop + containerHeight / 2)) < Math.abs(prev - (scrollTop + containerHeight / 2)) ? curr : prev;
    });

    const closestSectionIndex = sectionOffsets.indexOf(closestSection);
    const closestSectionName = closestSectionIndex === 0 ? 'topA' : closestSectionIndex === 1 ? 'middleA' : 'bottomA';

    if (selectedSection !== closestSectionName) {
      setSelectedSection(closestSectionName);
      console.log(`Scrolled to section: ${closestSectionName}`);
    }
  }, 100);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  
  return (
    <div>
      <Welcome/>
    <div className="noScroll">
      <div className="About">
        <div className="yesScroll" ref={scrollContainerRef}>
          <div className={`page_right ${selectedSection}`} ref={el => sectionRefs.current[0] = el}>
            <div className="aboutBox">
              <div className="floatAboutBox">
                <div className="washuIcon">
                  <div className="washuTitle">
                    Washington University in St. Louis
                  </div>
                  <img className="washuLogo" src={washuLogo} alt="WashU"></img>
                </div>
                <div className="info_row">
                  <div className="subtitleAbout">
                    Bachelors of Science in Computer Science
                  </div>
                  <div className="year">
                    2020 - 2024
                  </div>
                </div>
                <p>
                  I graduated from Washington University in St. Louis, where I had the privilege of playing on their Division 3 baseball team. I studied computer science with a minor in physics.
                  <ul>
                    <li>Serving as a TA for a quantum computing course.</li>
                    <li>Being awarded the Dean's List recognition multiple times.</li>
                    <li>Receiving the Iron Bear award from the athletic department.</li>
                  </ul>
                  Furthermore, I undertook two independent studies where I worked directly with Professor Ron Cytron. I was also involved in the community as a varsity event staff member, supporting athletics.
                </p>
              </div>
            </div>
          </div>
          <div className={`page_right ${selectedSection}`} ref={el => sectionRefs.current[1] = el}>
            <div className="aboutBox">
              <div className="floatAboutBox">
                <div className="washuIcon">
                  <div className="washuTitle">
                    International School of Bangkok
                  </div>
                  <div className="isbbackground">
                    <img className="isbLogo" src={isbLogo} alt="ISB"></img>
                  </div>
                </div>
                <div className="info_row">
                  <div className="subtitleAbout">
                    Full International Baccalaureate (IB)
                  </div>
                  <div className="year">
                    2016 - 2020
                  </div>
                </div>
                <p>
                  Alongside my academics, I was deeply involved in sports, playing five varsity sports throughout all four years. Additionally, I served as the co-president of the Varsity Council club, responsible for promoting, celebrating, and supporting athletics within our community.
                </p>
                <ul>
                  <li>Awarded the Terere Athletic Award for outstanding performance and dedication.</li>
                  <li>Honored with High Honors for achieving a score of 40+ in the International Baccalaureate (IB) program.</li>
                  <li>Recognized for academic achievement in Intermediate Japanese.</li>
                </ul>
                <p>
                  Furthermore, I had the opportunity to collaborate with a local Thai math and science program where I assisted in instructing, collecting data, and co-authoring a physics laboratory assignment on laminar umbrella flows. Outside of school, I actively engaged in community service by visiting stateless people on the Thailand-Myanmar border, teaching local students, and participating in painting and building projects.
                </p>
              </div>
            </div>
          </div>
          <div className={`page_right ${selectedSection}`} ref={el => sectionRefs.current[2] = el}>
            <div className="aboutBox">
              <div className="floatAboutBox">
                <div className="washuIcon">
                  <div className="washuTitle">
                    USA, Japan & Thailand
                  </div>
                  <div className="countriesBackground">
                    <img className="thailandLogo" src={thailand} alt="Thailand"></img>
                    <img className="japanLogo" src={japan} alt="Japan"></img>
                    <img className="usaLogo" src={usa} alt="USA"></img>
                  </div>
                </div>
                <div className="info_row">
                  <div className="subtitleAbout">
                    U.S Citizen & Japanese Citizen
                  </div>
                  <div className="year">
                    2001 - Present
                  </div>
                </div>
                <p>
                  I was born and raised in Bangkok, Thailand. Growing up as a third culture kid with my Japanese mother and American father immersed me in a unique fusion of cultures. This upbringing granted me dual citizenship in the U.S. and Japan and offered an indescribable cultural immersion.
                </p>
                <ul>
                  <li>Lived in 3 countries</li>
                  <li>Visited 23 countries</li>
                  <li>Speak English and Japanese</li>
                </ul>
                <p>
                  I am forever grateful for the opportunity to experience cultural differences, overcome language barriers, and approach interactions with others from an open and empathetic perspective.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='page_left'>
          <img className="timeline" src={timeline} alt="Timeline">
          </img>
          <div className={`ball ${selectedSection}`}>
          </div>
          <button
            className={`aboutButton ${selectedSection === 'topA' ? 'active' : ''}`}
            onClick={() => handleSectionChange('topA')}>University</button>
          <button
            className={`aboutButton ${selectedSection === 'middleA' ? 'active' : ''}`}
            onClick={() => handleSectionChange('middleA')}>Highschool</button>
          <button
            className={`aboutButton ${selectedSection === 'bottomA' ? 'active' : ''}`}
            onClick={() => handleSectionChange('bottomA')}>Childhood</button>
        </div>
      </div>
    </div >
    </div>
  );
}

export default About;
