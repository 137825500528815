import React, { useEffect } from 'react';
import './pg_experience.css';
import laptopOne from '../images/laptopOne.svg';
import laptopTwo from '../images/laptopTwo.svg';
import laptopThree from '../images/laptopThree.svg';
import invisiblyLogo from '../images/invisiblyLogo.png';
import amposLogo from '../images/amposLogo.png';
import mapIcon from '../icons/map.png';
import phoneOne from '../images/phoneOne.svg';
import phoneTwo from '../images/phoneTwo.svg';
import phoneThree from '../images/phoneThree.svg';
import KUTE from 'kute.js';

function Experience() {


  useEffect(() => {
    const laptopIds = ["laptopOne", "laptopTwo", "laptopThree"];
    const phoneIds = ["phoneOne", "phoneTwo", "phoneThree"];
    const laptoplogo = document.querySelector(".invisiblyLogo");
    const phonelogo = document.querySelector(".amposLogo");
    const laptops = laptopIds.map(id => document.getElementById(id));
    const phones = phoneIds.map(id => document.getElementById(id));

    const handleIntersection = (entries: any[], elements: any[]) => {
      entries.forEach(entry => {
        elements.forEach(element => {
          if (entry.isIntersecting) {
            if (element) {
              element.classList.add("visible");
            }
          } else {
            if (element) {
              element.classList.remove("visible");
            }
          }
        });
      });
    };

    const laptopObserver = new IntersectionObserver((entries) => handleIntersection(entries, laptops), {
      root: null,
      threshold: 0.5
    });

    const phoneObserver = new IntersectionObserver((entries) => handleIntersection(entries, phones), {
      root: null,
      threshold: 0.5
    });

    if (laptoplogo) {
      laptopObserver.observe(laptoplogo);
    }

    if (phonelogo) {
      phoneObserver.observe(phonelogo);
    }

    return () => {
      if (laptoplogo) {
        laptopObserver.unobserve(laptoplogo);
      }
      if (phonelogo) {
        phoneObserver.unobserve(phonelogo);
      }
    };
  }, []);




  return (
    <div className="experience">
      <div className="breaker">
      </div>
      <div className="page_experience">
        <div className="text_side">
          <div className="top">
            <div className="experience_title">
              <img className="invisiblyLogo" src={invisiblyLogo}></img>
            </div>
            <div className="location_invisibly">
              <div className="location_title">
                St. Louis, MO
              </div>
              <img className="mapIcon" src={mapIcon}></img>
            </div>
          </div>
          <div className="top_company">
            <p>Invisibly is a dynamic startup based in St. Louis, MO, founded by renowned entrepreneur Jim McKelvey, co-founder of Square (now Block). Invisibly primarily functions as a news app designed to monetize users' data. By providing personal information, users gain access to premium articles they would otherwise have to pay for.
            </p>
            <p>
              In my role at Invisibly, I developed a publisher onboarding website to streamline the process for new agency publishers. This involved simplifying contract signing, image handling, and RSS feed integration. Additionally, I collaborated with the marketing team on a side project, conducting A/B testing to enhance user engagement with articles shared via weblinks.
            </p>
          </div>
          <div className="bottom">
            <a href="https://www.invisibly.com/" target="_blank" rel="noopener noreferrer" className="learnMoreLink">
              <div className="learnMore">
                Learn more!
              </div>
            </a>
          </div>
        </div>
        <div className="picture_side_right">
          <div className="background_square_right">
            <img className="laptop" id="laptopOne" src={laptopOne}></img>
            <img className="laptop" id="laptopTwo" src={laptopTwo}></img>
            <img className="laptop" id="laptopThree" src={laptopThree}></img>
          </div>
        </div>

      </div>
      <div className="breaker_two">
      </div>
      <div id="page_bottom" className="page_experience">
        <div className="picture_side_left">
          <div className="background_square_left">
            <img id="phoneOne" className="phone" src={phoneOne}></img>
            <img id="phoneTwo" className="phone" src={phoneTwo}></img>
            <img id="phoneThree" className="phone" src={phoneThree}></img>
          </div>
        </div>
        <div className="text_side">
          <div className="top">
            <div className="experience_title">
              <img className="amposLogo" src={amposLogo}></img>
            </div>
            <div className="location_invisibly">
              <div className="location_title">
                Bangkok, Thailand
              </div>
              <img className="mapIcon" src={mapIcon}></img>
            </div>
          </div>
          <div className="top_company">
            <p>Ampos Solutions is a leading Software as a Service (SaaS) organization specializing in creating enterprise products, such as HR management systems. Based in Bangkok, Thailand, Ampos collaborates with numerous organizations across various industries.
            </p>
            <p>During my time at Ampos, I worked on a side project to develop a rapid prototype of a grading application for a renowned dental program at Chulalongkorn University. Following the UI designer's guidelines, we created a cross-platform mobile application designed to streamline grading and feedback for both students and professors. The previous implementation relied on traditional paper examinations and feedback forms. Our digital solution was presented to the board of directors to secure funding for transitioning to a more modern, digital approach.
            </p>
          </div>
          <div className="bottom">
            <a href="https://www.amposgroup.com/" target="_blank" rel="noopener noreferrer" className="learnMoreLink">
              <div className="learnMore">
                Learn more!
              </div>
            </a>
          </div>

        </div>
      </div>
      <div className="breaker_three">
      </div>
    </div>
  );
}

export default Experience;
