import React, { useEffect, useState } from 'react';
import './pg_references.css';
import ronImage from '../images/ronImage.avif';
import danteImage from '../images/danteImage.jpg';
import banchaImage from '../images/banchaImage.png';
import downloadIcon from '../icons/download.svg';
import back from '../icons/dark/back.svg';
const References: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [expandedBox, setExpandedBox] = useState<number | null>(null);

  const handleExpand = (boxIndex: number) => {
    if (expandedBox === null) {
      setExpandedBox(boxIndex);
    }
  };

  const handleDownload = (pdfUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="pages">
      <div className="gradient-bg">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
        <div className="gradients-container">
          <div className="g1"></div>
          <div className="g2"></div>
          <div className="g3"></div>
          <div className="g4"></div>
          <div className="g5"></div>
          <div className="interactive" style={{ transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)` }}></div>
        </div>
        <div className="References">
          <div
            className={`reference_box ${expandedBox === 1 ? 'expanded' : expandedBox !== null ? 'hidden' : ''}`}
            onClick={() => handleExpand(1)}
          >
            <div className={`left_side_referral ${expandedBox === 1 ? 'expanded' : ''}`}>
              <div className={`left_side_referral_top ${expandedBox === 1 ? 'expanded' : ''}`}>
                <div className={`image_box_ron ${expandedBox === 1 ? 'expanded' : ''}`}>
                  <img src={ronImage} alt="Ron Cytron" />
                </div>
                <div className={`vertical_display ${expandedBox === 1 ? 'expanded' : ''}`}>
                  <div className={`referral_name ${expandedBox === 1 ? 'expanded' : ''}`}>
                    Ron Cytron
                  </div>
                  <div className="referral_subtitle_job">Professor</div>
                  <div className="referral_subtitle">Washington University in St. Louis</div>
                </div>
              </div>
              <div className={`introduction ${expandedBox === 1 ? 'fade-in' : ''}`}>
                Ron Cytron was an influential professor who played a pivotal role in my academic journey. His mentorship extended beyond the classroom, making him not only a mentor but also a cherished friend.
              </div>
              <div className={`back_button ${expandedBox === null ? '' : 'fade-inBack'}`} onClick={() => setExpandedBox(null)}>
                <img className="back_icon" src={back} alt=""></img> Back
              </div>
            </div>
            <div className={`right_side_referral ${expandedBox === 1 ? 'fade-inPDF' : 'hiddenPDF'}`}>
              <div
                id="ron"
                className={`pdf_display ${expandedBox === 1 ? 'fade-inPDF' : 'hiddenPDF'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload('/RonCytronReccomendation.pdf', 'RonCytronReccomendation.pdf');
                }}
              >
                <img className="download_icon" src={downloadIcon} alt="download"></img>
              </div>
            </div>
          </div>
          
          <div
            className={`reference_box ${expandedBox === 2 ? 'expanded' : expandedBox !== null ? 'hidden' : ''}`}
            onClick={() => handleExpand(2)}
          >
            <div className={`left_side_referral ${expandedBox === 2 ? 'expanded' : ''}`}>
              <div className={`left_side_referral_top ${expandedBox === 2 ? 'expanded' : ''}`}>
                <div className={`image_box_dante ${expandedBox === 2 ? 'expanded' : ''}`}>
                  <img className="fill_image" src={danteImage} alt="Dante Cannarozzi" />
                </div>
                <div className={`vertical_display ${expandedBox === 2 ? 'expanded' : ''}`}>
                  <div className={`referral_name ${expandedBox === 2 ? 'expanded' : ''}`}>
                    Dante Cannarozzi
                  </div>
                  <div className="referral_subtitle_job">Head of Engineering</div>
                  <div className="referral_subtitle">Invisibly</div>
                </div>
              </div>
              <div className={`introduction ${expandedBox === 2 ? 'fade-in' : ''}`}>
                Dante Cannarozzi, the Head of Engineering at Invisibly, was my previous manager. He provided invaluable technical assistance and professional guidance throughout my tenure. Dante's support and mentorship have been instrumental in shaping my career.
              </div>
              <div className={`back_button ${expandedBox === null ? '' : 'fade-inBack'}`} onClick={() => setExpandedBox(null)}>
                <img className="back_icon" src={back} alt=""></img> Back
              </div>
            </div>
            <div className={`right_side_referral ${expandedBox === 2 ? 'fade-inPDF' : 'hiddenPDF'}`}>
              <div
                id="dante"
                className={`pdf_display ${expandedBox === 2 ? 'fade-inPDF' : 'hiddenPDF'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  console.log('Div clicked');
                  console.log('File path:', '/DanteLetterRecommendation.pdf');
                  handleDownload('/DanteLetterRecommendation.pdf', 'DanteLetterRecommendation.pdf');
                }}
              >
                <img className="download_icon" src={downloadIcon} alt="download"></img>
              </div>
            </div>
          </div>
          <div
            className={`reference_box ${expandedBox === 3 ? 'expanded' : expandedBox !== null ? 'hidden' : ''}`}
            onClick={() => handleExpand(3)}
          >
            <div className={`left_side_referral ${expandedBox === 3 ? 'expanded' : ''}`}>
              <div className={`left_side_referral_top ${expandedBox === 3 ? 'expanded' : ''}`}>
                <div className={`image_box_bancha ${expandedBox === 3 ? 'expanded' : ''}`}>
                  <img className="fill_image_bancha" src={banchaImage} alt="Bancha Dhammarungruang" />
                </div>
                <div className={`vertical_display ${expandedBox === 3 ? 'expanded' : ''}`}>
                  <div className={`referral_name ${expandedBox === 3 ? 'expanded' : ''}`}>
                    Bancha Dhammarungruang
                  </div>
                  <div className={`referral_subtitle_job ${expandedBox === 3 ? 'expanded' : ''}`}>
                    Founder & CEO
                  </div>
                  <div className={`referral_subtitle ${expandedBox === 3 ? 'expanded' : ''}`}>
                    Ampos Solution
                  </div>
                </div>
              </div>
              <div className={`introduction ${expandedBox === 3 ? 'fade-in' : ''}`}>
                Bancha Dhammarungruang (Beam), the founder and CEO of Ampos Solutions, was my boss. During my time at Ampos, he emphasized the importance of mindful decision-making and encouraged me to pursue my career aspirations.
              </div>
              <div className={`back_button ${expandedBox === null ? '' : 'fade-inBack'}`} onClick={() => setExpandedBox(null)}>
                <img className="back_icon" src={back} alt=""></img> Back
              </div>

            </div>
            <div className={`right_side_referral ${expandedBox === 3 ? 'fade-inPDF' : 'hiddenPDF'}`}>
              <div
                id="bancha"
                className={`pdf_display ${expandedBox === 3 ? 'fade-inPDF' : 'hiddenPDF'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload('/BanchaLetterRecommendation.pdf', 'BanchaLetterRecommendation.pdf');
                }}
              >
                <img className="download_icon" src={downloadIcon} alt="download"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default References;
